import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`subtitle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`close-icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification--error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#fff0f1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification--error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`svg.error--filled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification--success`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#dafbe4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification--success`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`svg.checkmark-filled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification--warning`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#fff0f1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification--warning`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`svg.warning-filled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification--info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rgba(253,209,58,.15)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notification--info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-04`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Notification text should be set in sentence case with only the first word capitalized. Notification titles should be concise and to the point.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification__title`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--inline-notification__title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiBold / 600`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification__subtitle`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`.bx--inline-notification__subtitle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <h3 {...{
      "id": "toast-notification"
    }}>{`Toast notification`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`288 / 18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification__title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification__subtitle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 / 1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-06`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification__details`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--toast-notification__caption`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-notification__close-button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`close-icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-top, margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "48.913043478260875%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Structure and spacing for a toast notification",
        "title": "Structure and spacing for a toast notification",
        "src": "/static/e767410c55ee759c81bb6e056def9f46/fb070/notification-style-1.png",
        "srcSet": ["/static/e767410c55ee759c81bb6e056def9f46/d6747/notification-style-1.png 288w", "/static/e767410c55ee759c81bb6e056def9f46/09548/notification-style-1.png 576w", "/static/e767410c55ee759c81bb6e056def9f46/fb070/notification-style-1.png 1152w", "/static/e767410c55ee759c81bb6e056def9f46/c3e47/notification-style-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "inline-notification"
    }}>{`Inline notification`}</h3>
    <p>{`The width of an `}<em parentName="p">{`inline notification`}</em>{` will vary based on content or layout.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-notification`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-09`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-notification`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-notification__details`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-left, margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-notification__text-wrapper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-top, padding-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-notification__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--inline-notification__close-button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`close-icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icon size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 x 16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "50.67934782608696%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Structure and spacing for an inline notification",
        "title": "Structure and spacing for an inline notification",
        "src": "/static/cc35faa159ee056bdcc5ece9b5d3dc47/fb070/notification-style-2.png",
        "srcSet": ["/static/cc35faa159ee056bdcc5ece9b5d3dc47/d6747/notification-style-2.png 288w", "/static/cc35faa159ee056bdcc5ece9b5d3dc47/09548/notification-style-2.png 576w", "/static/cc35faa159ee056bdcc5ece9b5d3dc47/fb070/notification-style-2.png 1152w", "/static/cc35faa159ee056bdcc5ece9b5d3dc47/c3e47/notification-style-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      